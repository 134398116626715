import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import "./our-team.less";

export default ({ data }) => {
  const ourTeamTitle = data.our_team_title.text;
  const headLawyerImg = data.head_lawyer_im.url;
  const headLawyerName = data.head_lawyer_name.text;
  const headLawyerPosition = data.head_lawyer_position.text;
  const lawyerDescription = data.lawyer_description;
  // const ourTeam = data.our_team;

  return (
    <section className="our-team-section">
      <article className="our-team-container">
        <Container>
          <Row>
            <Col xs={12} md={4} xl={5} xxl={6} className="team-col-one">
              <div className="wrapper">
                <h2>{ourTeamTitle}</h2>
                <div className="head-lawyer lawyer">
                  <img src={headLawyerImg} alt="" />
                  <h3>{headLawyerName}</h3>
                  <p>{headLawyerPosition}</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={8} xl={7} xxl={6} className="team-col-two">
              <div>
                {RichText.render(lawyerDescription.richText)}
                {/* {lawyerDescription.richText.map((rc, idx) => {
                  return <p key={idx}>{rc.text}</p>;
                })} */}
              </div>
              {/* <Row>
                {ourTeam.map((ot, idx) => {
                  count++;
                  return (
                    <Col xs={6} className="lawyer" key={idx}>
                      <img src={ot.team_member_img.url} alt="" />
                      <h4>{ot.team_member_title.text}</h4>
                      <p>{ot.team_member_position.text}</p>
                    </Col>
                  );
                })}
              </Row> */}
            </Col>
          </Row>
        </Container>
      </article>
    </section>
  );
};
