import React from "react";
import Slider from "react-slick";
import "./about-slider.less";

const AboutSlider = ({ data: { slider_images: sliderImages } }) => {
  return (
    <section className="about-slider">
      <article className="about-slider-wrapper">
        <Slider
          swipeToSlide={true}
          slidesToShow={1}
          focusOnSelect={true}
          autoplay={true}
          autoplaySpeed={6000}
          lazyLoad={true}
          cssEase={"ease-in-out"}
        >
          {sliderImages.map((si, idx) => {
            return (
              <div className="single-about-slide" key={idx}>
                <img src={si.slider_img.url} alt="" />
              </div>
            );
          })}
        </Slider>
      </article>
    </section>
  );
};

export default AboutSlider;
