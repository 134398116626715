import React from "react";
import { Container } from "react-bootstrap";
import { RichText } from "prismic-reactjs";

import "./about.less";

export const About = ({ data }) => {
  const aboutTitle = data.about_us_title.text;
  const aboutDesc = data.about_us_description;
  const aboutBckImg = data.about_us_background_img.url;

  return (
    <section className="about-container">
      <article className="about-wrapper">
        <img src={aboutBckImg} alt="" className="about-background" />
        <Container className="about-deatils">
          <h2>{aboutTitle}</h2>
          <div>{RichText.render(aboutDesc.richText)}</div>
        </Container>
      </article>
    </section>
  );
};
