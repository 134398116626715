import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Hero from "../components/AboutPage/Hero/Hero";
import { About } from "../components/AboutPage/About/About";
import OurTeam from "../components/AboutPage/OurTeam/OurTeam";
import AboutSlider from "../components/AboutPage/AboutSlider/AboutSlider";
import Results from "../components/Results/Results";

const AboutPage = (props) => {
  const { data } = props;
  const aboutData = data.allPrismicAboutPage.edges[0].node.data;
  return (
    <Layout>
      <Hero data={aboutData} />
      <About data={aboutData} />
      <OurTeam data={aboutData} />
      <Results />
      <AboutSlider data={aboutData} />
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  {
    allPrismicAboutPage {
      edges {
        node {
          data {
            about_us_background_img {
              url
            }
            about_us_title {
              text
            }
            about_us_description {
              html
              raw
              richText
              text
            }
            our_team_title {
              text
            }
            head_lawyer_im {
              url
            }
            head_lawyer_name {
              text
            }
            head_lawyer_position {
              text
            }
            lawyer_description {
              richText
              text
            }
            our_team {
              team_member_img {
                url
              }
              team_member_position {
                text
              }
              team_member_title {
                text
              }
            }
            abour_hero_description {
              text
            }
            about_button_text {
              text
            }
            about_hero_img {
              url
            }
            about_hero_title {
              text
            }
            slider_images {
              slider_img {
                url
              }
            }
          }
        }
      }
    }
  }
`;
